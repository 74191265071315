<template>
  <div id="path">
    <!-- Loader -->
    <sp-loader v-if="isLoading" />

    <!-- Top Panel w/breacrumb -->
    <div v-if="learningPath && !isLoading" class="jumbotron jumbotron-fluid texture texture-green sub-header header-padding" :style="{backgroundImage: `linear-gradient(180deg, rgba(65, 65, 65, 0.9) 0%, rgba(65, 65, 65, 0.9) 100%), url('${SPENV.S3_URL}/images/paths-thumbs/path-${learningPath.path_id}.jpg')`}">
      <div class="container">
        <Breadcrumbs :breadcrumb-prop="breadcrumb" />
        <h1 class="display-4 mt-2">
          {{ learningPath.display_title }}
        </h1>
        <p class="lead">
          {{ learningPath.sub_title | striphtml }}
        </p>
        <!-- if no progress show Start Learning else Resume Learning -->
        <button
          type="button"
          class="btn btn-primary ghost"
          :disabled="completePath(learningPath.courses)"
          @click="resumeLearningNav()">
          <span v-if="learningPath.progress === 0">Start</span>
          <span v-else>Resume</span>
          Learning
        </button>
      </div>
    </div>
    <div v-if="learningPath && !isLoading" class="container">
      <div class="row">
        <div class="col-12 mt-5">
          <h2 class="text-center font-weight-normal">
            {{ learningPath.call_to_action }}
          </h2>
          <p class="mt-4">
            {{ learningPath.body_description }}
          </p>
          <div class="mt-4 mb-4">
            <h4 class="font-weight-bold py-4 text-center">
              What you'll learn
            </h4>
            <div v-if="learningPath && learningPath.bullets" class="row justify-content-center">
              <!--the font size change here is to make the li bullets smaller  -->
              <ul class="col-12 col-md-6 col-lg-4 pl-5 font-size-12">
                <li v-for="learning in getEvenBulletPoints(learningPath.bullets)" :key="learning.rowid" class="pl-2 pb-3">
                  <span class="font-size-16">{{ learning }}</span>
                </li>
              </ul>
              <ul class="col-12 col-md-6 col-lg-4 pl-5 font-size-12">
                <li v-for="learning in getOddBulletPoints(learningPath.bullets)" :key="learning.rowid" class="pl-2 pb-3">
                  <span class="font-size-16">{{ learning }}</span>
                </li>
              </ul>
            </div>
            <div class="row py-3 justify-content-center">
              <div class="col-12 text-center">
                <span v-if="learningPath.certificate" class="badge badge-pill badge-med-gray py-2 px-4 mr-4 mb-2">Technical certificate available</span>
                <span class="badge badge-pill badge-med-gray py-2 px-4">{{ duration(learningPath.duration) }} of content</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Top progress bar -->
      <b-nav id="learning-path-nav" justified class="bg-white font-133em mb-3">
        <b-nav-item
          v-for="(lpath, index) in learningPath.courses"
          :key="lpath.drmid"
          v-scroll-to="'#learning-path-' + lpath.drmid"
          class="path-incomplete"
          :active="formatProgress(lpath.progress) === progressMax ? true : false">
          <strong>{{ index + 1 }}</strong>
        </b-nav-item>
        <b-nav-item
          v-scroll-to="'#learning-path-complete'"
          class="path-incomplete"
          :active="completePath(learningPath.courses) ? true : false">
          <font-awesome-icon icon="graduation-cap" size="lg" />
        </b-nav-item>
      </b-nav>
      <div class="row justify-content-center">
        <div class="col-sm-12">
          <ul class="list-group-shadow list-group">
            <div v-for="(lpath, index) in learningPath.courses" :key="lpath.drmid" class="row path-course">
              <span :id="'learning-path-' + lpath.drmid" />

              <!-- Progress meters -->
              <div class="col-md-3 col-lg-2 py-4 d-none d-sm-flex align-items-center justify-content-center">
                <div class="path-number" :class="formatProgress(lpath.progress) === progressMax ? 'path-complete': 'path-incomplete'">
                  <strong>{{ index + 1 }}</strong>
                </div>
                <svg class="path-progress" width="120" height="120"
                     viewBox="0 0 120 120">
                  <circle cx="60" cy="60" r="54" />
                  <circle
                    class="path-progress-value"
                    :style="{'stroke-dashoffset': formatProgressRadius(formatProgress(lpath.progress))}"
                    cx="60"
                    cy="60"
                    r="54"
                    :stroke-dashoffset="formatProgressRadius(formatProgress(lpath.progress))" />
                </svg>
              </div>

              <!-- Path details -->
              <div class="col-md-9 col-lg-10">
                <a :href="'/' + SPENV.LIBRARY_FOLDER_DISPLAY + '/' + lpath.software_slug + '/' + lpath.course_slug" append class="list-group-item list-group-item-action my-3"
                   @click.prevent="goToCourse(lpath)">
                  <div class="row media p-3">
                    <div class="col-10 offset-1 offset-lg-0 col-lg-3 text-center align-self-center mb-2 path-course-image">
                      <b-img-lazy
                        class="mr-3" :src="SPENV.S3_URL + '/images/course-thumbs/' + lpath.drmid + '.jpg'"
                        alt="Learning Path thumbnail"
                        onerror="this.onerror=null;this.src='/static/images/courses/placeholder-transparent.png'" />
                    </div>
                    <div class="col media-body m-2">
                      <h4 class="font-weight-semibold mt-0 mb-1">
                        {{ lpath.title }}
                      </h4>
                      <div v-if="lpath.description" v-line-clamp:24="'3'" class="text-muted mb-2 preserve-word">
                        <div> {{ lpath.description | striphtml }} </div>
                      </div>
                      <div class="badge" :class="formatProgress(lpath.progress) === 100 ? 'badge-primary' : 'badge-light text-muted'">
                        {{ formatProgress(lpath.progress) }}% Done
                      </div>
                      <div class="badge badge-light text-muted">
                        {{ duration(lpath.duration) }}
                      </div>
                    </div>
                  </div>
                </a>

                <!-- Path seperator -->
                <div class="justify-content-center d-flex align-items-center">
                  <div class="path-separator bg-sp-gray-300" />
                </div>
              </div>
            </div>

            <!-- Completed path -->
            <div class="row">
              <div class="col-md-9 offset-md-3 col-lg-10 offset-lg-2 text-center py-4">
                <div id="learning-path-complete" class="list-group-item py-5" :class="completePath(learningPath.courses) ? 'path-complete' : 'path-incomplete'">
                  <font-awesome-icon icon="graduation-cap" size="6x" />
                  <p class="pt-2">
                    Path completed!
                  </p>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseComponent from '@/components/BaseComponent';
import bodyClass from '@/utils/mixins/BodyClass';
import Breadcrumbs from '@/components/Breadcrumbs';

export default {
  name: 'LearningPath',
  components: { Breadcrumbs },
  extends: BaseComponent,
  mixins: [bodyClass],
  data() {
    return {
      bodyClass: 'body-offwhite',
      progressMax: (100).toFixed(0)
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.$store.getters['user/getUser']) {
        vm.$store.dispatch('header/loadHeaderData');
      }
    });
  },
  computed: {
    learningPath() {
      return this.$store.getters['paths/getPath'](this.$route.params.pathslug);
    }
  },
  created() {
    this.init();
  },
  methods: {
    /**
     * Initialize any data needed for page
     * @return {Void}
     */
    init() {
      this.isLoading = true;
      if (!this.learningPath || !this.learningPath.courses) {
        let path = this.$store.dispatch('paths/loadPathBySlug', this.$route.params.pathslug);
        let progress = this.$store.dispatch('paths/loadPathProgressBySlug', this.$route.params.pathslug);
        Promise.all([path, progress]).then(
          (response) => {
            this.$store.commit('paths/setPathDetails', response);
            this.createBreadcrumb(this.learningPath);
            this.isLoading = false;
          });
      } else {
        this.$store.dispatch('paths/loadPathProgressBySlug', this.$route.params.pathslug).then(
          (response) => {
            let updatedProgress = {
              path_slug: this.$route.params.pathslug,
              progress: response
            };
            this.$store.commit('paths/setPathProgress', updatedProgress);
            this.createBreadcrumb(this.learningPath);
            this.isLoading = false;
          });
      }
    },
    /**
     * Get hours duration jhamm 9/26/18
     * @param seconds
     */
    duration(seconds) {
      let hours = parseInt(seconds / 3600);
      return hours + ' hours ';
    },
    /**
     * Format course progress jhamm 9/26/18
     * @param {Int} progress - percent of course completed
     * @return {Int}
     */
    formatProgress(progress) {
      let pathProgress = progress || 0.0;
      return pathProgress.toFixed(0);
    },
    /**
     * Create breadcrumb for course page jhamm 9/26/18
     * @param path
     * @param path
     */
    createBreadcrumb(path) {
      this.breadcrumb = [
        { label: 'Paths', route: { name: 'paths' } },
        {
          label: path.name,
          route: { name: 'path', params: { lpathslug: path.software_slug } }
        }
      ];
    },
    /**
     * Get even numbered bullet points from learning path jhamm 9/26/18
     * @return {Array}
     * @param bullets
     */
    getEvenBulletPoints(bullets) {
      let evenBulletPoints = [];
      for (let i = 0; i < bullets.length; i++) {
        if (bullets[i].rowid % 2 === 0) {
          evenBulletPoints.push(bullets[i].details);
        }
      }
      return evenBulletPoints;
    },
    /**
     * Get odd numbered bullet points from learning path jhamm 9/26/18
     * @return {Array}
     * @param bullets
     */
    getOddBulletPoints(bullets) {
      let oddBulletPoints = [];
      for (let i = 0; i < bullets.length; i++) {
        if (Math.abs(bullets[i].rowid % 2) === 1) {
          oddBulletPoints.push(bullets[i].details);
        }
      }
      return oddBulletPoints;
    },
    /**
     * Check if learning path is complete jhamm 9/26/18
     * @param {Array} courses - selected learning path courses
     * @return {Boolean}
     */
    completePath(courses) {
      for (let i = 0; i < courses.length; i++) {
        if (courses[i].progress !== 100) {
          return false;
        }
      }
      return true;
    },
    /**
     * Format progress percent for radius jhamm 9/26/18
     * @param {Int} progress - course progress percent
     * @return {Int} progress - formateed course progress percent
     */
    formatProgressRadius(progress) {
      return 339.292 * ((100 - progress) / 100);
    },
    /**
     * Find the first course which is not complete and navigate there jhamm 9/26/18
     * @return {Void}
     */
    resumeLearningNav () {
      for (let i = 0; i < this.learningPath.courses.length; i++) {
        if (this.formatProgress(this.learningPath.courses[i].progress) !== this.progressMax) {
          this.analyticsClickEvent(
            'Learning Path',
            'Engage - start/resume',
            'pathId: ' + this.learningPath.path_id
          );
          this.$router.push({
            name: 'course',
            params: {
              swslug: this.learningPath.courses[i].software_slug,
              courseslug: this.learningPath.courses[i].course_slug
            }
          }).catch(err => {});
          break;
        }
      }
    },
    /**
     * Go to a course in a learning path
     * @param course
     */
    goToCourse(course) {
      this.analyticsClickEvent('Learning Path', 'Engage - course', 'pathId: ' + this.learningPath.path_id + ', courseId: ' + course.drmid);
      this.$router.push({
        name: 'course',
        params: { swslug: course.software_slug, courseslug: course.course_slug }
      }).catch(err => {});
    }
  }
};
</script>

<style scoped></style>
