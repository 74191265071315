<template>
  <div />
</template>
<script>
import analyticsClickHandler from '@/utils/mixins/Analytics';
import FullPageLoader from '@/utils/mixins/FullPageLoader';
import SpLoader from '@/components/SPLoader';
import redirectMixin from '@/utils/mixins/Redirect';

export default {
  components: {
    'sp-loader': SpLoader
  },
  mixins: [FullPageLoader, analyticsClickHandler, redirectMixin],
  data() {
    return {
      isLoading: false
    };
  },
};
</script>
